import { useDataSource } from '@/hooks';

export const useInviteData = () => useDataSource('invite', {
    isLoading: true,
    invite: null,
    error: null,
    lastSmsTime: 0,
    userInfo: {
        avatar: null,
        id: null,
        name: null,
    },
    mobile: null
});
