import {DataSource} from './DataSource';

const cache = {};

/**
 * DataSource manager
 */
export const dsm = {
    resolve(id, initData) {
        return cache[id] = cache[id] || DataSource.create(initData);
    },
    destroy(id) {
        const source = cache[id];
        if (source) {
            delete cache[id];
        }
    },
    /**
     * 初始化基础数据
     */
    init() {
        dsm.resolve('user', {});
    },
};

