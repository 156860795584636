import qs from 'query-string';
import {createBrowserHistory, createHashHistory} from 'history';

import {dsm} from '@/ds';
import {device} from './device';

const formatLocation = (location, action) => {
    const {pathname, search} = location;
    const query = qs.parse(search);
    return {type: action, pathname, query};
};

/**
 * 初始化store信息
 */
export const syncLocation = () => {
    const history = (window.history.pushState || device.wechat) ? createBrowserHistory() : createHashHistory();
    // 根据当前url信息初始化store信息
    const ds = dsm.resolve('location', formatLocation(history.location, 'REPLACE'));
    /**
     * 'store': 数据变化 => url 变化
     * 'browser': 浏览器操作 => store 变化
     */
    let trigger = null;

    history.listen((location, action) => {
        if (trigger === 'store') {
            trigger = null;
            return;
        }

        trigger = 'browser';
        ds.update(() => formatLocation(location, action));
    });

    ds.subscribe(() => {
        if (trigger === 'browser') {
            trigger = null;
            return;
        }

        trigger = 'store';
        const {pathname, query} = ds.select();
        const search = qs.stringify(query);
        history.push({pathname, search: search ? `?${search}` : ''});
    });
};
