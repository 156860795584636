import PropTypes from 'prop-types';
import React from 'react';

import ss from './Popover.less';
import {Portal} from '../portal/Portal';

export class Popover extends React.Component {
    handleClickMask = (e) => {
        if (this.props.onClickMask) {
            this.props.onClickMask(e);
        }
    };

    render() {
        const {id, holderId, visible, maskBackground = '#000', maskOpacity = 0.6, children} = this.props;
        return (
            <Portal id={id} holderId={holderId} visible={visible}>
                <div className={ss.wrapper}>
                    <div className={ss.mask} style={{backgroundColor: maskBackground, opacity: maskOpacity}}
                         onClick={this.handleClickMask}/>
                    {children}
                </div>
            </Portal>
        );
    }
}

Popover.propTypes = {
    holderId: PropTypes.string,
    visible: PropTypes.bool,
    maskBackground: PropTypes.string,
    maskOpacity: PropTypes.number,
    onClickMask: PropTypes.func,
};
