export const TYPE = {
    APPEND: 1,
    UPDATE: 2,
    REMOVE: 3,
};

const all = {};

export const subscribe = (type, handler) => {
    let list = all[type] || (all[type] = []);
    list.push(handler);

    return {
        remove() {
            const len = list.length;
            if (len < 1) {
                return;
            }

            let eventHandler;
            for (let i = len - 1; i > -1; i--) {
                eventHandler = list[i];
                if (eventHandler === handler) {
                    list.splice(i, 1);
                }
            }
        }
    };
};

export const publish = (type, payload) => {
    if (!(type in all)) {
        return;
    }

    const list = all[type];
    list.filter(o => !!o).forEach(handler => handler(payload));
};
