import React from 'react';

import { PATHS } from '@/const';
import { Route } from '@/widget';

import ss from './InviteRouter.less';
import { useInviteData } from './hooks';
import { useInviteLoader } from './InviteRouter.hooks';
import { HomePage } from './HomePage';
import { OrganizationCreatePage } from './OrganizationCreatePage';
import { NewUserConfirmPage } from './NewUserConfirmPage';
import { OldUserConfirmPage } from './OldUserConfirmPage';
import { ResultPage } from './ResultPage';

export function InviteRouter () {
    const { isLoading, error } = useInviteData();

    useInviteLoader();

    if (isLoading) {
        return (
            <div className={ss.info}>正在加载数据...</div>
        );
    }

    if (error) {
        if (error.code === 1003000) {
            return (
                <div className={ss.info}>邀请码无效或已过期</div>
            );
        }

        return (
            <div className={ss.info}>数据加载失败</div>
        );
    }

    return (
        <>
            <Route pathname={PATHS.USER_INVITE_HOME.pathname} component={HomePage}/>
            <Route pathname={PATHS.ORGANIZATION_CREATE.pathname} component={OrganizationCreatePage}/>
            {/*<Route pathname={PATHS.USER_INVITE_NEW_USER.pathname} component={NewUserConfirmPage}/>*/}
            {/*<Route pathname={PATHS.USER_INVITE_OLD_USER.pathname} component={OldUserConfirmPage}/>*/}
            <Route pathname={PATHS.USER_INVITE_SUCCESS.pathname} component={ResultPage}/>
        </>
    );
}
