import React from 'react';

export const SVG = {
    MOBILE: ({style, height, width, fill})=>{
        return (
          <svg className={style || {}} width={width} height={height}
               viewBox="0 0 1024 1024" version="1.1">
              <path fill={fill || "#333333"}
                    d="M512.3072 883.3024c-41.2672 0-74.8544-33.5872-74.8544-74.8544s33.5872-74.8544 74.8544-74.8544 74.8544 33.5872 74.8544 74.8544S553.5744 883.3024 512.3072 883.3024zM512.3072 770.4576c-20.992 0-37.9904 16.9984-37.9904 37.9904s16.9984 37.9904 37.9904 37.9904 37.9904-16.9984 37.9904-37.9904S533.1968 770.4576 512.3072 770.4576zM680.1408 991.9488c-3.1744 0-6.4512-0.8192-9.4208-2.56-41.2672-24.4736-108.032-24.3712-108.7488-24.3712 0 0-0.1024 0-0.1024 0L276.8896 965.0176c-54.3744 0-98.5088-44.2368-98.5088-98.5088L178.3808 140.3904c0-54.3744 44.2368-98.5088 98.5088-98.5088l470.7328 0c54.3744 0 98.5088 44.2368 98.5088 98.5088l0 726.1184c0 54.3744-44.2368 98.5088-98.5088 98.5088-10.1376 0-18.432-8.2944-18.432-18.432s8.2944-18.432 18.432-18.432c33.9968 0 61.6448-27.648 61.6448-61.6448L809.2672 140.3904c0-33.9968-27.648-61.6448-61.6448-61.6448L276.8896 78.7456c-33.9968 0-61.6448 27.648-61.6448 61.6448l0 726.1184c0 33.9968 27.648 61.6448 61.6448 61.6448l284.8768 0c4.1984 0 78.0288-0.1024 127.7952 29.4912 8.704 5.2224 11.6736 16.4864 6.4512 25.2928C692.5312 988.7744 686.4896 991.9488 680.1408 991.9488zM589.824 149.7088 434.7904 149.7088c-10.1376 0-18.432-8.2944-18.432-18.432s8.2944-18.432 18.432-18.432L589.824 112.8448c10.1376 0 18.432 8.2944 18.432 18.432S599.9616 149.7088 589.824 149.7088zM705.024 230.0928c9.728 0 17.7152 7.8848 17.7152 17.7152l0 376.9344c0 9.728-7.8848 17.7152-17.7152 17.7152L319.5904 642.4576c-9.728 0-17.7152-7.8848-17.7152-17.7152L301.8752 247.808c0-9.728 7.8848-17.7152 17.7152-17.7152L705.024 230.0928M705.024 193.2288 319.5904 193.2288c-30.1056 0-54.5792 24.4736-54.5792 54.5792l0 376.9344c0 30.1056 24.4736 54.5792 54.5792 54.5792l385.4336 0c30.1056 0 54.5792-24.4736 54.5792-54.5792L759.6032 247.808C759.6032 217.7024 735.1296 193.2288 705.024 193.2288L705.024 193.2288z"/>
          </svg>
        );
    },
    SAFETY: ({style, height, width, fill})=>{
        return (
          <svg className={style || {}} width={width} height={height}
               viewBox="0 0 1024 1024" version="1.1">
              <path fill={fill || "#333333"}
                    d="M512.948148 896c-1.422222 0-2.844444-0.18963-4.266667-0.474074-3.223704-0.663704-80.497778-16.877037-158.53037-71.86963-46.174815-32.521481-83.057778-71.964444-109.605926-117.191111-33.185185-56.414815-49.967407-121.931852-49.967407-194.465185V267.188148c0-11.757037 9.576296-21.333333 21.333333-21.333333 157.202963 0 285.013333-111.407407 286.245926-112.545185 7.964444-7.016296 19.911111-7.111111 27.97037-0.18963 0.284444 0.284444 33.943704 28.823704 86.376297 56.794074 47.691852 25.505185 121.362963 55.940741 201.386666 55.940741 11.757037 0 21.333333 9.576296 21.333334 21.333333v244.717037c-0.948148 72.059259-18.299259 137.197037-51.674074 193.611852-26.642963 45.131852-63.431111 84.574815-109.321482 117.285926-77.463704 55.277037-153.78963 71.964444-156.918518 72.628148-1.422222 0.379259-2.939259 0.568889-4.361482 0.568889zM233.244444 287.952593V512c0 117.57037 47.597037 210.773333 141.463704 276.859259 60.681481 42.761481 121.552593 59.828148 138.145185 63.905185 16.592593-4.266667 77.653333-22.091852 137.955556-65.517037 92.539259-66.465185 140.231111-159.288889 141.842963-275.721481V287.952593c-32.995556-1.896296-66.939259-8.154074-101.357037-18.773334-33.185185-10.145185-66.74963-24.367407-99.84-42.097778-36.124444-19.342222-63.525926-38.684444-78.980741-50.346666-15.17037 11.757037-42.002963 31.004444-77.558518 50.346666-32.711111 17.825185-66.180741 31.952593-99.365926 42.192593-34.512593 10.524444-68.835556 16.782222-102.305186 18.678519zM479.762963 645.30963c-10.145185 0-19.721481-4.456296-26.263704-12.136297L340.574815 500.906667c-7.68-8.912593-6.637037-22.471111 2.37037-30.056297 8.912593-7.68 22.471111-6.637037 30.056296 2.370371l106.571852 124.776296 198.162963-234.002963c7.585185-9.007407 21.048889-10.145185 30.056297-2.465185 9.007407 7.585185 10.145185 21.048889 2.465185 30.056296L505.647407 633.362963c-6.447407 7.585185-15.834074 11.946667-25.884444 11.946667z"/>
          </svg>
        );
    },
};
