import React from 'react';

import {useLocation} from '@/hooks';

export function Route({pathname, exact = true, component}) {
    const location = useLocation();
    if (exact) {
        if (location.pathname !== pathname) {
            return null;
        }
    } else {
        if (location.pathname.indexOf(pathname) !== 0) {
            return null;
        }
    }

    if (!component) {
        return null;
    }

    const Component = component;
    return <Component/>;
}
