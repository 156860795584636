import React from 'react'

import ss from './SmsCodeButton.less'
import { useButtonState } from './SmsCodeButton.hooks'

export const SmsCodeButton = ({ mobile }) => {
    const { disabled, text, handleClick } = useButtonState(mobile)

    return (
        <button disabled={disabled} className={ss.btnCode} onClick={handleClick}>{text}</button>
    )
}
