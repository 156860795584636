import axios from 'axios';

import { URLS, DOMAIN_TYPE } from '@/const';
import { http } from '@/util';

export const userInviteApi = {
    /**
     * 获取邀请信息
     * @param {string} id
     * @return {*}
     */
    getInviteDetail (id) {
        return http.get(URLS.USER_INVITE_DETAIL, { linkId: id }, {
            formatter (data) {
                data.mapKeys({ inviteName: 'invitorName' });
                return data;
            }
        });
    },

    /**
     * 获取组织列表（不需要用户认证）
     * @param domainId
     * @param domainType
     * @returns {Promise<any>|*|Promise}
     */
    getOrganizationList ({ domainId, domainType }) {
        return http.get(URLS.ORGANIZATION_LIST, { domainId, domainType });
    },

    /**
     * 获取手机验证码
     * @param {string} mobile
     * @param {string} source
     * PWD_RECOVERY: 手机密码找回
     * MOBILE_BINDING: 手机号绑定
     * ADMIN_ENTRANCE: 管理入口
     * ORDER_MANAGEMENT: 订单管理
     * VERIFY_CODE_LOGIN: 手机验证码登录
     * INFO_CHANGE: 信息变更
     * @returns {*}
     */
    getSmsCode (mobile, source) {
        return http.post(URLS.SMS_CODE, { mobile, source });
    },

    /**
     * 提交申请加入项目/企业信息
     * @param domainType
     * @param params
     * @param {Object} params.deviceType xx
     * @param {Object} params.linkId xx
     * @param {Object} params.loginName xx
     * @param {Object} params.loginPlatform xx
     * @param {Object} params.loginType xx
     * @param {Object} params.password xx
     * @param {Object} params.reason xx
     * @param {Object} params.remember xx
     * @param {Object} params.userName xx
     * @param {Object} params.verifyCode xx
     * @returns {Promise<any>|*}
     */
    createApplication (domainType, params) {
        const url = domainType === DOMAIN_TYPE.ENTERPRISE ? URLS.INVITE_APPLICATION_CREATE_FOR_ENTERPRISE : URLS.INVITE_APPLICATION_CREATE_FOR_PROJECT;
        return http.post(url, params);
    },

    /**
     * download user privacy
     * */
    getPolicy ({ url }) {
        const promise = axios({
            url: url,
            method: 'get',
            withCredentials: false,
        });
        return promise;
    },

    /**
     * check if the verify code is correct
     * */
    validateVerifyCode ({ mobile, code, source }) {
        return http.post(URLS.VALIDATE_SMS_CODE, { mobile, code, source });
    },

    /**
     * search exists org
     * */
    searchExistOrg ({ keywords, domainId }) {
        return http.post(URLS.ORGANIZATION_SEARCH, { keywords, domainId, domainType: 'PROJECT' });
    },

    /**
     * create organization
     * */
    createOrganization ({ account, checkMessage, linkId, organizationName, projectId, userName }) {
        return http.post(URLS.ORGANIZATION_CREATE, {
            account,
            checkMessage,
            linkId,
            organizationName,
            projectId,
            userName
        });
    },

    /**
     * create organization
     * */
    inviteMember ({ account, checkMessage, linkId, organizationName, projectId, userName }) {
        return http.post(URLS.MEMBER_INVITE, {
            account,
            checkMessage,
            linkId,
            organizationName,
            projectId,
            userName
        });
    },
};
