import '@/util/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import {syncLocation} from '@/util';
import {dsm} from '@/ds';

import {App} from './module/App';
import {unregister} from './serviceWorker';

dsm.init();

syncLocation();

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
