import {useState, useEffect} from 'react';

/**
 * @param apiFunc
 * @param params
 * @returns [pending, data, error]
 */
export const useApi = (apiFunc, params = []) => {
    const [state, setState] = useState([true, null, null]);
    useEffect(() => {
        (async () => {
            const {data, error} = await apiFunc.apply(null, params);
            if (data) {
                setState([false, data, null]);
            }
            if (error) {
                setState([false, null, error]);
            }
        })();
    }, params);
    return state;
};
