import produce from 'immer';

export class DataSource {

    static create(initData) {
        return new DataSource(initData);
    }

    constructor(initData) {
        this.data = initData;
        this.changeHandlerMap = {};
        this.counter = 0;
    }

    handleChange(newData, oldData) {
        const {changeHandlerMap} = this;
        Object.keys(changeHandlerMap).forEach(id => {
            if (id in changeHandlerMap) {
                const {selector, onChange} = changeHandlerMap[id];
                const newValue = selector(newData);
                const oldValue = selector(oldData);
                if (newValue !== oldValue) {
                    onChange(newValue, oldValue);
                }
            }
        });
    }

    select(selector = o => o) {
        return selector(this.data);
    }

    update(updater) {
        const newData = produce(this.data, updater);
        const oldData = this.data;
        this.data = newData;
        this.handleChange(newData, oldData);
    }

    subscribe(onChange, selector = o => o) {
        const {changeHandlerMap} = this;
        const id = this.counter++;
        changeHandlerMap[id] = {selector, onChange};
        return {
            remove() {
                delete changeHandlerMap[id];
            }
        };
    }
}
