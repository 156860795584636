import { useCallback, useEffect, useState } from 'react'

import { dsm } from '@/ds'
import { validator, mobileUtil } from '@/util';
import { userInviteApi } from '@/api'

const INTERVAL = 60 * 1000
export const useButtonState = (mobile) => {
    const [now, setNow] = useState(Date.now())
    useEffect(() => {
        const timerId = setInterval(() => setNow(Date.now()), 500)
        return () => clearInterval(timerId)
    })

    const ds = dsm.resolve('invite')
    const diff = now - ds.select(o => o.lastSmsTime)
    const countdown = diff > INTERVAL ? 0 : parseInt((INTERVAL - diff) / 1000)
    const disabled = validator.validateMobile(mobile) || countdown !== 0

    const text = countdown === 0 ? '获取验证码' : `${countdown}秒后获取`
    const handleClick = useCallback(() => {
        const ds = dsm.resolve('invite')
        ds.update(draft => {
            draft.lastSmsTime = Date.now()
        })
        userInviteApi.getSmsCode(mobileUtil.getMobile(mobile), 'IDENTITY_CHECK')
    })
    return { disabled, text, handleClick }
}
