let protocol = window.location.protocol;
// let protocol = 'http:'

///////////////////
///////////////////
//////////

///////////////////
///////////////////
//////////

export const SERVERS = {
  SERVER_API: protocol + '//apps.bimernet.com',
  SERVER_IMAGE: protocol + '//apps.bimernet.com',
}

///////////////////
////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////

///////////////////
///////////////////////////////////////////////////
/////////////////////////////////////////////////////
//////////
