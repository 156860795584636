import React from 'react';
import cn from 'classnames';

import css from './css.less';
import ss from './ResultPage.less';
import { useLocation } from '@/hooks';
import { useInviteData } from './hooks';

export function ResultPage () {

    const { query: { type } } = useLocation();
    const { invite } = useInviteData();
    const projectName = invite && invite.project ? invite.project.name : '';
    return (
        <div className={cn(ss.content, css.flexColumn, ss.main)}>
            <div className={cn(css.flexRow)}>
                <img src={'https://cdn.bimernet.com/app/download/img/icon180.png'} className={ss.logo}/>
                <span className={ss.productName}>毕美云图</span>
            </div>
            <div className={cn(css.font20normal, css.marinTop16)}>操作成功</div>
            {
                projectName ? (
                    <div className={cn(css.marinTop8, css.font14normal, ss.tips)}>
                        已经进入{projectName}项目
                    </div>
                ) : null
            }
            <img src={'https://cdn.bimernet.com/app/download/img/download.png'} className={ss.downloadImg}/>
        </div>
    );
}
