import PropTypes from 'prop-types';
import React from 'react';

import {subscribe, TYPE} from './controller';

export class PortalHolder extends React.Component {
    constructor(props) {
        super(props);
        this.portals = [];
        this.subscriptions = [];

        this.subscribeEvents();

        this.state = {
            counter: 0,
        };
    }

    refresh() {
        this.setState({counter: this.state.counter + 1});
    }

    subscribeEvents() {
        const myId = this.props.id;
        this.subscriptions = [
            subscribe(TYPE.APPEND, ({id, holderId, view}) => {
                if (holderId !== myId) {
                    return;
                }

                if (this.portals.some(o => o.id === id)) {
                    this.refresh();
                    return;
                }

                this.portals.push({id, view});
                this.refresh();
            }),
            subscribe(TYPE.UPDATE, ({id, holderId, view}) => {
                if (holderId !== myId) {
                    return;
                }

                this.portals = this.portals.filter(o => o.id !== id);

                this.portals.push({id, view});

                this.refresh();
            }),
            subscribe(TYPE.REMOVE, ({id, holderId}) => {
                if (holderId !== myId) {
                    return;
                }

                const portals = this.portals;
                const len = portals.length;
                if (len < 1) {
                    return;
                }

                let portal;
                for (let i = len - 1; i > -1; i--) {
                    portal = portals[i];
                    if (portal.id === id) {
                        portals.splice(i, 1);
                    }
                }
                this.refresh();
            }),
        ];
    }

    render() {
        let content = null;
        if (this.portals.length < 1) {
            if (this.props.children) { // 没有嵌入视图时，显示默认视图
                content = this.props.children;
            }
        } else {
            content = this.portals.map(o => o.view);
        }

        return content;
    }

    componentWillUnmount() {
        this.subscriptions.forEach(o => o.remove());
        this.subscriptions = [];
    }
}

PortalHolder.propTypes = {
    id: PropTypes.string,
};
