import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { List, InputItem, Button, Modal, Toast } from 'antd-mobile';

import 'antd-mobile/lib/input-item/style/css';
import 'antd-mobile/lib/list/style/css';
import 'antd-mobile/lib/button/style/css';
import 'antd-mobile/lib/modal/style/css';
import 'antd-mobile/lib/toast/style/css';
import css from './css.less';
import ss from './HomePage.less';
import { dsm } from '@/ds';
import { SVG, PATHS } from '@/const';
import { useInput } from '@/hooks';
import { userInviteApi } from '@/api';
import { mobileUtil } from '@/util';
import { useInviteData } from './hooks';
import { SmsCodeButton } from './components/SmsCodeButton';
import { POLICY_TYPE } from './enum';
import { policyContentData, PolicyContentLoader } from './Policy.hooks';

export function HomePage () {
    const { invite } = useInviteData();
    const projectName = invite && invite.project ? invite.project.name || '' : '';
    const [mobile, , handleMobileChange] = useInput('');
    const [verifyCode, , handleVerifyCodeChange] = useInput('');

    const [privacyType, setPrivacyType] = useState('');

    PolicyContentLoader({ type: privacyType });
    const { PRIVACY_PROTOCOL, SERVICE_AGREEMENT } = policyContentData({ type: privacyType });

    const title = POLICY_TYPE.PRIVACY_PROTOCOL === privacyType ? '毕美云图平台隐私政策' : '毕美云图平台服务协议';

    const content = POLICY_TYPE.PRIVACY_PROTOCOL === privacyType ? PRIVACY_PROTOCOL :
        POLICY_TYPE.SERVICE_AGREEMENT === privacyType ? SERVICE_AGREEMENT : '';

    const visible = privacyType === POLICY_TYPE.PRIVACY_PROTOCOL || privacyType === POLICY_TYPE.SERVICE_AGREEMENT;

    const closest = (el, selector) => {
        const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
        while (el) {
            if (matchesSelector.call(el, selector)) {
                return el;
            }
            el = el.parentElement;
        }
        return null;
    };

    const onNextClick = useCallback((event) => {
        event.preventDefault();
        (async () => {
            const { data, error } = await userInviteApi.validateVerifyCode({
                mobile: mobileUtil.getMobile(mobile),
                code: verifyCode,
                source: 'IDENTITY_CHECK'
            });
            if (error && error.message) {
                Toast.fail(error.message, 2);
            } else if (data) {
                //save userInfo
                let ds = dsm.resolve('invite');
                ds.update((draft) => {
                    draft.userInfo = data;
                    draft.mobile = mobile;
                });
                // jump to create organization
                ds = dsm.resolve('location');
                const { invite } = ds.select(o => o.query);
                ds.update(() => ({ type: 'PUSH', pathname: PATHS.ORGANIZATION_CREATE.pathname, query: { invite } }));
            }
        })();
    });

    return (
        <div className={cn(css.pageWrapper, ss.wrapper)}>
            <div>
                <div className={cn(ss.tips, css.left)}>提醒 : 若无法正常跳转，请点击右上角，用浏览器打开</div>
                <div className={cn(css.left, css.font18bold, css.marin20)}>欢迎您加入</div>
                <div className={cn(css.left, css.font20normal, ss.projectName)}>{projectName} 项目</div>
            </div>
            <div className={ss.content}>
                <div
                    className={cn(css.left, css.marinTop8, css.font12normal, css.mainText)}>*为保障您的正常使用，请根据系统提示，完成相关信息验证和完善：
                </div>
                <div className={cn(css.left, css.marinTop8, css.font14normal, css.mainText)}>第1步：请验证您的手机号</div>
                <div className={cn(css.left, css.marinTop16)}>
                    <List>
                        <InputItem placeholder="请输入您的手机号" type={'phone'} labelNumber={2} className={css.mainText}
                                   value={mobile} onChange={handleMobileChange}>
                            {SVG.MOBILE({ height: '22px', width: '22px' })}
                        </InputItem>
                    </List>
                    <div className={cn(css.tbWrapper, ss.codeWrapper)}>
                        <div className={ss.tbCodeWrapper}>
                            <InputItem placeholder="输入短信验证码" labelNumber={2} className={css.mainText}
                                       value={verifyCode} onChange={handleVerifyCodeChange} maxLength={5}>
                                {SVG.SAFETY({ height: '22px', width: '22px' })}
                            </InputItem>
                        </div>
                        <div className={ss.btnCodeWrapper}>
                            <SmsCodeButton mobile={mobile}/>
                        </div>
                    </div>
                    <div className={cn(css.marinTop24, css.cursorPointer)}>
                        <Button type={'primary'} disabled={!verifyCode || !mobile}
                                onClick={onNextClick}>下一步</Button>
                    </div>
                </div>
            </div>
            <div className={ss.btnDownloadWrapper}>
                <div className={ss.tips}>注意 : 点击下一步，则意味着您接受以下平台使用协议</div>
                <div className={css.marinTop16}>
                    <span onClick={() => {setPrivacyType(POLICY_TYPE.PRIVACY_PROTOCOL);}}
                          className={cn(css.textBlue, css.font12normal, css.cursorPointer)}>《毕美云图平台隐私政策》&nbsp;&nbsp;</span>
                    <span onClick={() => {setPrivacyType(POLICY_TYPE.SERVICE_AGREEMENT);}}
                          className={cn(css.textBlue, css.font12normal, css.cursorPointer)}>&nbsp;&nbsp;《毕美云图平台服务协议》</span>
                </div>
            </div>

            <Modal
                visible={visible}
                transparent
                maskClosable={false}
                title={title}
                wrapClassName={'fullScreenModal'}
                footer={[{ text: '同意并继续', onPress: () => { setPrivacyType(POLICY_TYPE.NONE);} }]}
                // wrapProps={{ onTouchStart: (e)=>{
                //         if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
                //             return;
                //         }
                //         const pNode = closest(e.target, '.am-modal-content');
                //         if (!pNode) {
                //             e.preventDefault();
                //         }
                // } }}
            >
                <div className={ss.policyContentWrapper}>
                    <div className={ss.policyContent}>{content}</div>
                </div>
            </Modal>
        </div>
    );
}
