/**
 * app 路由path
 * alias 用于埋点
 */
export const PATHS = {
    HOME: {
        pathname: '/',
        alias: 'home',
    },
    USER_INVITE: {
        pathname: '/m/invite',
        alias: 'user-invite',
    },
    USER_INVITE_HOME: {
        pathname: '/m/invite',
        alias: 'user-invite',
    },
    ORGANIZATION_CREATE: {
        pathname: '/m/invite/organization',
        alias: 'user-invite',
    },
    USER_INVITE_NEW_USER: {
        pathname: '/user/invite/new-user',
        alias: 'user-invite',
    },
    USER_INVITE_OLD_USER: {
        pathname: '/user/invite/old-user',
        alias: 'user-invite',
    },
    USER_INVITE_SUCCESS: {
        pathname: '/m/invite/success',
        alias: 'user-invite',
    },
};
