export const validator = {
    validateMobile(mobile) {
        if (!mobile){
            return '您输入的手机号码不正确';
        }
        const trim = mobile.replace(/\s*/g, '')
        const pattern = /^1\d{10}$/;
        if (pattern.test(trim)) {
            return null;
        }

        return '您输入的手机号码不正确';
    },

    validateSmsCode(code) {
        const pattern = /^\d{5}$/;
        if (pattern.test(code)) {
            return null;
        }

        return '您输入的验证码格式不正确';
    },
};