import React, { useState, useCallback, useEffect } from 'react';
import { Button, InputItem, Icon, Toast } from 'antd-mobile';
import { debounce } from 'lodash';
import cn from 'classnames';

import 'antd-mobile/lib/input-item/style/css';
import 'antd-mobile/lib/icon/style/css';
import css from './css.less';
import ss from './HomePage.less';
import oss from './OrganizationCreatePage.less';

import { dsm } from '@/ds';
import { mobileUtil } from '@/util';
import { userInviteApi } from '@/api';
import { PATHS } from '@/const';
import { useInput } from '@/hooks';
import { RESULT_TYPE } from './enum';
import { useInviteData } from './hooks';

let orgRef = { state: {} };

export function OrganizationCreatePage () {
    const { userInfo, invite, mobile } = useInviteData();
    useEffect(() => {
        if (!mobile) {
            const ds = dsm.resolve('location');
            const { invite } = ds.select(o => o.query);
            ds.update(() => ({ type: 'PUSH', pathname: PATHS.USER_INVITE_HOME.pathname, query: { invite } }));
        }
    }, []);

    const { project, inviteId, type, organization } = invite;
    const organizationName = organization ? (organization.name || '') : '';
    //是否成员邀请
    const isMemberInvite = 'ORDINARY' === type || 'ordinary' === type;
    const projectId = project.id;
    const { id, name } = userInfo;
    const [userName, , handleUserNameChange] = useInput('');
    const [inviteName, , handleInviteNameChange] = useInput('');

    const [showSearch, setShowSearch] = useState(false);
    const [existOrganizations, setExistOrganizations] = useState([]);
    const [loading, setLoading] = useState(false);

    const projectName = invite && invite.project ? invite.project.name || '' : '';

    const searchOrg = debounce((value) => {
        if (!orgRef || !orgRef.state.value) {
            setShowSearch(false);
            return;
        }
        (async () => {
            const { data, error } = await userInviteApi.searchExistOrg({
                keywords: orgRef.state.value || '',
                domainId: projectId,
            });
            if (data && data.items && data.items.length > 0) {
                const orgs = data.items.map(item => {
                    return <div className={oss.searchItem} key={item.id} onClick={() => {
                        orgRef.setState({ value: item.name });
                        setShowSearch(false);
                    }}>{item.name}</div>;
                });
                setExistOrganizations(orgs);
                setShowSearch(true);
            } else {
                setShowSearch(false);
            }
        })();
    }, 300);

    const renderOrganization = () => {
        return (
            <div className={cn(css.flexColumn, css.marinTop16)}>
                <div className={css.flexRow}>
                    <div className={cn(css.font14normal, css.mainText, oss.label)}>
                        {isMemberInvite ? null : <span className={cn(css.textRed, oss.textRequired)}>*</span>}
                        组织名称：&nbsp;
                    </div>
                    {
                        isMemberInvite ? (
                            <div className={cn(css.font14normal, css.mainText, oss.infoLeft)} title={organizationName}>
                                {organizationName}
                            </div>
                        ) : renderOrganizationSearch()
                    }
                </div>
                <div className={cn(css.flexRow, css.marinTop8)}>
                    <div className={cn(css.font14normal, css.mainText, oss.label)}>
                        <span className={cn(css.textRed, oss.textRequired)}>*</span>验证信息：&nbsp;
                    </div>
                    <div className={oss.inputName}>
                        <InputItem placeholder="邀请人平台用户名" labelNumber={2} className={css.mainText}
                                   value={inviteName} onChange={handleInviteNameChange}/>
                    </div>
                </div>
            </div>
        );
    };

    const renderOrganizationSearch = () => {
        return (<div className={oss.inputName}>
            <InputItem placeholder="请输入组织名称" labelNumber={2} className={css.mainText}
                       onChange={searchOrg} ref={input => orgRef = input}/>
            {
                showSearch ? <div className={cn(oss.searchWrap)}>{existOrganizations}</div> : null
            }
        </div>);
    };

    const renderUnregisterUser = () => {
        return (
            <div className={css.flexColumn}>
                <div className={css.flexRow}>
                    <div className={cn(css.font14normal, css.mainText, oss.label)}>
                        <span className={cn(css.textRed, oss.textRequired)}>*</span>姓名：&nbsp;
                    </div>
                    <div className={oss.inputName}>
                        <InputItem placeholder="请输入姓名" labelNumber={2} className={css.mainText}
                                   value={userName} onChange={handleUserNameChange}/>
                    </div>
                </div>
                <div className={cn(css.flexRow, css.marinTop8)}>
                    <div className={cn(css.font14normal, css.mainText, oss.label)}>
                        手机：&nbsp;
                    </div>
                    <div className={cn(css.font14normal, css.mainText, oss.info)}>
                        {mobile || ''}
                    </div>
                </div>
                {
                    renderOrganization()
                }
            </div>
        );
    };
    const renderRegisterUser = () => {

        return (
            <div className={css.flexColumn}>
                <div className={oss.registerInfo}>
                    <div className={cn(css.font14normal, css.mainText, oss.info)}>
                        {name || ''}
                    </div>
                    <div className={css.flexRow}>
                        <div className={cn(css.font14normal, css.mainText, oss.info)}>
                            {mobile || ''}
                        </div>
                        <div className={oss.avatar}>
                            <Icon type="check" size={'xs'} color={'#67c457'}/>
                        </div>
                    </div>

                </div>
                {
                    renderOrganization()
                }
            </div>
        );
    };

    const onFinishClick = useCallback((event) => {
        event.preventDefault();
        setShowSearch(false);
        setLoading(true);
        if (isMemberInvite) {
            (async () => {
                const { data, error } = await userInviteApi.inviteMember({
                    account: mobileUtil.getMobile(mobile),
                    checkMessage: inviteName,
                    linkId: inviteId,
                    organizationName: organizationName,
                    projectId,
                    userName: userName || name,
                });
                if (error && error.message) {
                    Toast.fail(error.message, 2);
                } else if (data) {
                    const ds = dsm.resolve('location');
                    ds.update(() => ({
                        type: 'PUSH',
                        pathname: PATHS.USER_INVITE_SUCCESS.pathname,
                        query: { type: RESULT_TYPE.SUCCESS, invite: inviteId }
                    }));
                }
                setLoading(false);
            })();
        } else {
            (async () => {
                const { data, error } = await userInviteApi.createOrganization({
                    account: mobileUtil.getMobile(mobile),
                    checkMessage: inviteName,
                    linkId: inviteId,
                    organizationName: orgRef ? orgRef.state.value : '',
                    projectId,
                    userName: userName || name,
                });
                if (error && error.message) {
                    Toast.fail(error.message, 2);
                } else if (data) {
                    const ds = dsm.resolve('location');
                    ds.update(() => ({
                        type: 'PUSH',
                        pathname: PATHS.USER_INVITE_SUCCESS.pathname,
                        query: { type: RESULT_TYPE.SUCCESS, invite: inviteId }
                    }));
                }
                setLoading(false);
            })();
        }
    });

    return (
        <div className={cn(css.pageWrapper, ss.wrapper)}>
            <div>
                <div className={cn(ss.tips, css.left)}>提醒 : 若无法正常跳转，请点击右上角，用浏览器打开</div>
                <div className={cn(css.left, css.font18bold, css.marin20)}>欢迎您加入</div>
                <div className={cn(css.left, css.font18normal, ss.projectName)}>{projectName} 项目</div>
            </div>
            <div className={cn(ss.content, ss.flexSpaceBetween)}>
                <div>
                    <div className={cn(css.left, css.marinTop8, css.font14normal, css.mainText)}>第2步：信息完善和确认</div>
                    <div className={cn(css.left, css.marinTop16)}>
                        {
                            id ? (
                                renderRegisterUser()
                            ) : (
                                renderUnregisterUser()
                            )
                        }
                    </div>
                </div>
                <div className={cn(css.cursorPointer)}>
                    <Button type={'primary'}
                            disabled={loading || (isMemberInvite ? (!inviteName) : ((!orgRef ? false : !orgRef.state.value) || !inviteName))}
                            onClick={onFinishClick}>完&nbsp;成</Button>
                </div>
            </div>
        </div>
    );
}
