import { SERVERS } from './servers'

const { SERVER_API } = SERVERS

export const URLS = {
  APP_STORE_URL: 'https://itunes.apple.com/cn/app/apple-store/id1505988728',
  APK_DOWNLOAD_URL: 'https://cdn.bimernet.com/app/download/apk/bimernet_clouddrawing.apk',

  SMS_CODE: `${SERVER_API}/api/customer/sms/v1.0/code/create`,
  VALIDATE_SMS_CODE: `${SERVER_API}/api/customer/sms/v1.0/link/code/validate`,
  USER_INVITE_DETAIL: `${SERVER_API}/api/member/invitation/v2.0/link/check`,
  INVITE_APPLICATION_CREATE_FOR_ENTERPRISE: `${SERVER_API}/api/member/application/v2.0/enterprise/create`,
  INVITE_APPLICATION_CREATE_FOR_PROJECT: `${SERVER_API}/api/customer/application/v1.0/create`,
  ORGANIZATION_LIST: `${SERVER_API}/api/member/organization/v1.0/entity/list`,
  ORGANIZATION_SEARCH: `${SERVER_API}/api/member/organization/v1.0/base/list`,
  ORGANIZATION_CREATE: `${SERVER_API}/api/member/invitation/v2.0/organization/member/invitation`,
  MEMBER_INVITE: `${SERVER_API}/api/member/invitation/v2.0/member/invitation`,
}
