import PropTypes from 'prop-types';
import React from 'react';

import {TYPE, publish} from './controller';

export class Portal extends React.Component {
    render() {
        return null;
    }

    componentDidMount() {
        const {id, holderId, visible, children} = this.props;
        const view = visible ? children : null;
        publish(TYPE.APPEND, {id, holderId, view});
    }

    componentWillReceiveProps(nextProps) {
        const {id, holderId, visible, children} = nextProps;
        const view = visible ? children : null;
        publish(TYPE.UPDATE, {id, holderId, view});
    }

    componentWillUnmount() {
        const {id, holderId} = this.props;
        publish(TYPE.REMOVE, {id, holderId});
    }
}

Portal.propTypes = {
    id: PropTypes.string,
    holderId: PropTypes.string,
    visible: PropTypes.bool,
};

Portal.defaultProps = {
    visible: true,
};
