import {useEffect, useState} from 'react';

import {dsm} from '@/ds';

export const useDataSource = (key, initData) => {
    const ds = dsm.resolve(key, initData);
    const [data, setData] = useState(ds.select());

    useEffect(() => {
        const subscription = ds.subscribe((data) => setData(data));
        return () => subscription.remove();
    }, [data]);

    return data;
};