import { useEffect } from 'react';

import { dsm } from '@/ds';
import { userInviteApi } from '@/api';
import { useDataSource } from '@/hooks';
import { POLICY_TYPE } from './enum';

const SERVICE_AGREEMENT = 'https://cdn.bimernet.com/privacy/ServiceAgreementv2';
const PRIVACY_PROTOCOL = 'https://cdn.bimernet.com/privacy/PrivacyProtocolv2';

const formatPolicyResponse = (response) => {
    let result = null;
    if (response && response.data) {
        result = (typeof response.data) === 'string' ? JSON.parse(response.data) : response.data;
    }
    return result.content;
};

export const policyContentData = ({ type }) => useDataSource('privacy-policy', {
    PRIVACY_PROTOCOL: null,
    SERVICE_AGREEMENT: null,
});

export const PolicyContentLoader = ({ type }) => {

    useEffect(() => {
        if (!type || POLICY_TYPE.NONE === type) {
            return;
        }
        (async () => {
            const ds = dsm.resolve('privacy-policy');
            if (type === POLICY_TYPE.PRIVACY_PROTOCOL && ds.data && ds.data.PRIVACY_PROTOCOL) {
                return;
            }
            if (type === POLICY_TYPE.SERVICE_AGREEMENT && ds.data && ds.data.SERVICE_AGREEMENT) {
                return;
            }
            const url = type === POLICY_TYPE.PRIVACY_PROTOCOL ? PRIVACY_PROTOCOL : SERVICE_AGREEMENT;

            const { data } = await userInviteApi.getPolicy({ url });
            if (data) {
                const content = formatPolicyResponse(data);
                ds.update((draft) => {
                        if (POLICY_TYPE.PRIVACY_PROTOCOL === type) {
                            draft.PRIVACY_PROTOCOL = content;
                        } else if (POLICY_TYPE.SERVICE_AGREEMENT === type) {
                            draft.SERVICE_AGREEMENT = content;
                        }
                    }
                );
            }
        })();
    }, [type]);// 每次弹出都请求一次数据
};
