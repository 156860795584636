import React from 'react';

import './App.less';
import {PATHS} from '@/const';
import {Route, PortalHolder} from '@/widget';
import {InviteRouter} from './user/invite/InviteRouter';

export function App() {
    return (
        <>
            <Route pathname={PATHS.HOME.pathname} component={InviteRouter}/>
            <Route pathname={PATHS.USER_INVITE.pathname} component={InviteRouter} exact={false}/>
            <PortalHolder id="popover"/>
        </>
    );
}
