import axios from 'axios';

import {ERROR_CODE, PATHS, SERVER_ERROR_CODE, URLS} from '@/const';
import {dsm} from '@/ds';

// 全局设置，允许跨域
axios.defaults.withCredentials = true;

/**
 * 无需验证授权的API地址集合
 * @type {*[]}
 */
const authWhiteList = [
    // URLS.AUTH_CHECK,
    // URLS.AUTH_LOGIN,
    // URLS.AUTH_REGISTER,
];

/**
 * 无需添加默认Header的API地址集合
 * @type {*[]}
 */
const headWhiteList = [
    URLS.SMS_CODE,
    URLS.USER_INVITE_DETAIL,
    URLS.INVITE_APPLICATION_CREATE_FOR_PROJECT,
];

const defaultOptions = {
    withDefaultProcess: true,
    useFormData: false,
    /**
     * 统一格式化数据
     */
    formatter: null,
};

/**
 * 默认处理,返回true时，表示无需继续处理
 * @param reply
 * @param context
 * @return {boolean}
 */
const defaultHandle = (reply, context) => {
    console.log('>> Default Handle:');
    console.log('>>>>>>>>>>>>> url:', context.url);
    console.log('>>>>>>>>>> params:', context.params);
    console.log('>>>>>>>>>>> reply:', reply);
    // Access-Token失效
    if (reply.code === SERVER_ERROR_CODE.ACCESS_TOKEN_INCORRECT) {
        // const location = dsm.resolve('location').select();
        // if (location.pathname !== PATHS.USER_AUTH_LOGIN.pathname) {
        //     // const {publish} = require('@/core');
        //     // publish(CHANNELS.LOCATION, EVENTS_LOCATION.PUSH, {pathname: PATHS.USER_AUTH_LOGIN.pathname, query: {from: location}});
        // }
        return true;
    }

    return false;
};

const checkAuth = (url) => {
    if (authWhiteList.indexOf(url) < 0) {
        // const token = authStorage.getToken();
        // if (!token) {
        //     const query = {};
        //     if (window.location.pathname !== PATHS.AUTH_LOGIN_VIEW) {
        //         query.from = encodeURIComponent(window.location.href);
        //     }
        //     history.push({pathname: PATHS.AUTH_LOGIN_VIEW, query});
        //     return false;
        // }
    }

    return true;
};

const createHeaders = (url) => {
    if (headWhiteList.indexOf(url) > -1) {
        return {};
    }

    const {token} = dsm.resolve('user').select();
    return {
        'Access-Token': token
    };
};

/**
 * 有serverTime信息则视为新版本格式
 * @param response
 * @returns {*}
 */
const formatResponse = response => {
    if (response.serverTime) {
        return response;
    }

    const {errCode, errMsg: message, ...rest} = response;
    const code = errCode === -1 ? 0 : errCode;
    return {code, message, data: rest};
};

const onSuccess = (resolve, options, context) => {
    return ({data: response}) => {
        const reply = formatResponse(response);
        if (reply.code === 0) {
            const data = options.formatter ? options.formatter(reply.data) : reply.data;
            resolve({data});
        } else {
            if (options.withDefaultProcess) {
                if (defaultHandle(reply, context)) {
                    resolve({});
                    return;
                }
            }
            resolve({error: {code: reply.code, message: reply.msg}});
        }
    };
};

const onError = resolve => {
    return (error, msg) => {
        resolve({error: {code: ERROR_CODE.NETWORK_ERROR, message: msg, error}});
    };
};

const convertPromise = (promise, options, context) => {
    return new Promise((resolve) => {
        promise.then(onSuccess(resolve, {...defaultOptions, ...options}, context), onError(resolve));
    });
};

const _delete = (url, params, options) => {
    if (!checkAuth(url)) {
        return Promise.resolve({});
    }
    return convertPromise(axios.delete(url, {params, headers: createHeaders(url)}), options, {url, params, options});
};

const get = (url, params, options) => {
    if (!checkAuth(url)) {
        return Promise.resolve({});
    }

    return convertPromise(axios.get(url, {params, headers: createHeaders(url)}), options, {url, params, options});
};

const post = (url, params, options) => {
    if (!checkAuth(url)) {
        return Promise.resolve({});
    }

    let contentType = 'application/json';
    let data = params;
    if (options && options.useFormData) {
        contentType = 'multipart/form-data';
        data = new FormData();
        Object.keys(params).forEach(key => data.append(key, params[key]));
    }

    const headers = {...createHeaders(url), 'Content-Type': contentType};
    return convertPromise(axios.post(url, data, {headers}), options, {url, params, options});
};

const put = (url, params, options) => {
    if (!checkAuth(url)) {
        return Promise.resolve({});
    }

    return convertPromise(axios.put(url, params, {headers: createHeaders(url)}), options, {url, params, options});
};

export const http = {delete: _delete, get, post, put};
