/**
 * 添加key映射方法
 * eg. {_id: 1001, name: 'Li Lei'}.mapKeys({_id: 'id', name: ()=>'userName'})
 *     ==> {id: 1001, userName: 'Li Lei'}
 */
Object.defineProperty(Object.prototype, 'mapKeys', {
        writable: false,     // Cannot alter this property
        enumerable: false,   // Will not show up in a for-in loop.
        configurable: false, // Cannot be deleted via the delete operator
        value: function (map, newObject = false) {
            const that = this;
            const result = newObject ? {} : that;
            const mapKeys = Object.keys(map);
            Object.keys(that).forEach(key => {
                if (mapKeys.indexOf(key) > -1) {
                    const mapValue = map[key];
                    const type = typeof mapValue;
                    let newKey = undefined;
                    if (type === 'function') {
                        newKey = mapValue();
                    } else if (type === 'string') {
                        newKey = mapValue;
                    }
                    if (newKey === undefined) {
                        throw 'map value should be function or string';
                    } else {
                        result[newKey] = that[key];
                        if (!newObject) {
                            delete that[key];
                        }
                    }
                } else if (newObject) {
                    result[key] = that[key];
                }
            });
            return result;
        }
    }
);
