import { useEffect } from 'react';

import { dsm } from '@/ds';
import { useLocation } from '@/hooks';
import { userInviteApi } from '@/api';

export const useInviteLoader = () => {
    const { query: { invite } } = useLocation();
    useEffect(() => {
        (async () => {
            const ds = dsm.resolve('invite');
            const { data, error } = await userInviteApi.getInviteDetail(invite);
            if (data) {
                const { linkId, type, organization, project, creator } = data;
                ds.update((draft) => {
                    draft.isLoading = false;
                    draft.invite = {
                        inviteId: linkId,
                        type: type.toUpperCase(),
                        organization,
                        project,
                        creator,
                    };
                    draft.error = null;
                });
            }
            if (error) {
                ds.update((draft) => {
                    draft.isLoading = false;
                    draft.invite = null;
                    draft.error = error;
                });
            }
        })();
        return () => dsm.destroy('invite');
    }, [invite]);
};
